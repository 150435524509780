import { ADMIN_DETAIL, JWT_TOKEN } from '../action/types'
const INITIAL_STATE = {
  detail: null,
  jwtToken: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_DETAIL:
      if (payload.status) {
        return {
          ...state,
          detail: payload,
        };
      }
    case JWT_TOKEN:
      return {
        ...state,
        jwtToken: payload,
      };
    default:
      return state;
  }
};
