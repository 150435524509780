import { Suspense, lazy, useEffect, } from "react";
import { Helmet } from "react-helmet"
import { Provider } from "react-redux";
import "./routes/layout.css"
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import logo from './assets/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image } from "antd";

const AppRouter = lazy(() => import("./routes/index"));
function App() {

  useEffect(() => {
    window.addEventListener('popstate', function (e) {
      sessionStorage.clear()
    });

    window.addEventListener('pagehide', function (event) {
      // when the user navigates away from the page, including when they close the tab or window.
      sessionStorage.clear()
    });
  }, [])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={
          <div className="loading d-flex justify-content-center align-items-center flex-column" style={{ minHeight: "70vh" }}>
            <Image src={logo} alt="" preview={false} />
            <h6>Loading...</h6>
          </div>}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Tip Top</title>
            <link rel="canonical" href="https://tiptopcontrol.com/admin/assets/images/logo-light-text.png" />
            <meta property="og:image" content="https://tiptopcontrol.com/admin/assets/images/logo-light-text.png" />
          </Helmet>
          <AppRouter />
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;