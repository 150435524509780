//persisit work
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import root from "../src/redux/reducer/index";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["common" ] 
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, root);

const store = createStore(
    persistedReducer,
    // compose(
    applyMiddleware(thunk),
    //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    // )
);

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };